::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
    margin-right: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #d5e6f7; /* color of the thumb */
    border-radius: 6px; /* radius of the thumb */
    height: 6px !important;
}
  